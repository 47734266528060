

export default function Navbar({ logout }) {
    return (
        <div className="flex justify-end items-center bg-gradient-to-r from-black to-gray-800">
            <nav className="p-1 w-full lg:w-60 ">
                <button onClick={logout} className=" border rounded-full  py-1.5 lg:py-0.5 px-4 bg-red-500 flex justify-center items-center w-full text-xl lg:text-md  text-white font-semibold cursor-pointer">SALIR</button>
            </nav>

        </div>
    )
}
