import eeuu from '../../images/eeuu.png'
import arg from '../../images/argentina.png'
import iconoStock from '../../images/stockDisponible.png'


export default function CardArticulo({ articulos, openModal }) {

    const formatPrice = (price) => {
        const parts = price?.split('.');
        if (parts?.length > 1) {
            parts[1] = parts[1]?.substring(0, 2);
            return parts?.join('.');
        }
        return price;
    };


    return (
        <>
            {articulos?.map((art, index) => (
                <div
                    key={index}
                    className='flex justify-center items-center flex-col bg-lime-100  lg:rounded-md  mt-1.5 mb-1.5 m-1   cursor-pointer transition-transform hover:scale-95 '
                    onClick={() => openModal(art)}  // Abrir el modal al hacer clic en un artículo
                >
                    <p className='text-center text-sm font-semibold'>Ultima Actualización: {art?.fupcosto}</p>
                    <div className='flex justify-center items-center -mb-1'>
                        <img src={art?.Monedadolar.trim() == '1' ? eeuu : arg} alt="bandera" className='w-6 h-6 mr-4' />
                        <h5 className=' text-center font-bold mr-4'>{art?.Codigo}</h5>
                        {art?.Stockactual?.trim() !== '0' && (
                            <img src={iconoStock} alt="stock" className='w-7 h-6' />
                        )}
                    </div>

                    <h5 className='text-md text-center text-blue-700 font-bold px-0 lg:px-2'>{art?.Descripcion?.trim()}</h5>


                    <div className='flex justify-between  lg:justify-center  w-full  px-1 py-0 lg:px-2 lg:py-2'>

                        <div className='self-start mr-0 lg:mr-4'>
                            <p ><strong className=' font-black'>PvtaP1 :</strong> {formatPrice(art?.Prevtapub1)}</p>
                            <p><strong className=' font-black'>PvtaP2 :</strong> {formatPrice(art?.Prevtapub2)}</p>
                            <p><strong className=' font-black'>PvtaP3 :</strong> {formatPrice(art?.Prevtapub3)}</p>
                        </div>

                        <div className='self-end text-left'>
                            <p><strong className=' font-black'>PvtaM1 :</strong> {formatPrice(art?.Prevtamay1)}</p>
                            <p><strong className=' font-black'>PvtaM2 :</strong> {formatPrice(art?.Prevtamay2)}</p>
                            <p><strong className=' font-black'>PvtaM3 :</strong> {formatPrice(art?.Prevtamay3)}</p>
                        </div>
                    </div>
                </div>

            ))
            }
        </>
    )
}
