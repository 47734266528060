import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import MensajeToken from '../MensajeToken/MensajeToken';
import Articulos from '../Articulos/Articulos';



export default function Layout() {

    const navigate = useNavigate()


    const logout = async () => {
        try {
            // Elimina el token de localStorage
            localStorage.removeItem('auth_usuario');
            navigate('/login');
        } catch (error) {
            console.log(error.response?.data?.message);
        }
    };

    return (

        <>
            <div className='flex flex-col min-h-screen bg-gradient-to-r from-black to-gray-800'>
                <div className=''>
                    <Navbar logout={logout} />
                </div>
                <div className='flex-grow h-full'>
                    <Articulos logout={logout} />
                </div>
            </div>

        </>


    )
}
